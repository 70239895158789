<template>
    <v-skeleton-loader v-if="fetchingAllData" type="article, table"></v-skeleton-loader>
    <v-card flat v-else>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Application resources</span>
        </v-card-title>
        <v-card-subtitle>{{ selectedApp.long_id }}</v-card-subtitle>

        <v-card-text>
            <v-card class="mt-5 overflow-hidden d-flex">
                <v-tabs v-model="tab">
                    <v-tab>Included</v-tab>
                    <v-tab>Credit</v-tab>
                </v-tabs>
                <v-btn
                    v-if="tab === 1"
                    text
                    tile
                    class="py-6"
                    :to="mappedResourcePoolId ? { name: 'reporting-dashboard', query: { rpid: mappedResourcePoolId } } : ''">
                    <div class="font-weight-medium d-flex align-center">
                        <span class="mr-1 secondary--text font-weight-bold">Current balance:</span>
                        <v-chip color="error lighten-4 error--text" small v-if="$isError(resourcePoolBalance) || resourcePoolBalance === null">
                            <v-icon small color="warning" left>mdi-alert-rhombus-outline</v-icon>
                            error
                        </v-chip>
                        <b v-else class="primary--text">{{ toLocaleFixed(resourcePoolBalance) }}</b>
                        <v-icon right small v-if="mappedResourcePoolId">mdi-open-in-new</v-icon>
                    </div>
                </v-btn>
            </v-card>

            <v-tabs-items v-model="tab" class="mt-3">
                <v-tab-item>
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-alert text type="info" class="mb-6">{{ NCUHint }}</v-alert>
                            <div class="d-flex align-center">
                                <v-spacer></v-spacer>
                                <v-text-field
                                    prepend-inner-icon="mdi-filter"
                                    autofocus
                                    hide-details
                                    solo
                                    flat
                                    background-color="grey lighten-4"
                                    dense
                                    label="Search for a resource..."
                                    v-model="search.standard"
                                    clearable
                                    class="mr-2"></v-text-field>
                            </div>
                            <v-row no-gutters class="py-4">
                                <v-col cols="2">
                                    <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('cpu', 'standard')">
                                        <span class="caption font-weight-bold">vCPU</span>
                                        <v-icon small :class="headerClasses.standard.cpu">mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="3">
                                    <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('memory', 'standard')">
                                        <span class="caption font-weight-bold">Memory</span>
                                        <v-icon small :class="headerClasses.standard.memory">mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="7" class="d-flex align-center justify-end">
                                    <span class="caption font-weight-bold px-4 text--secondary">Actions</span>
                                </v-col>
                            </v-row>
                            <v-data-iterator
                                :items="filteredNCUOptions"
                                no-data-text="No resources found."
                                :search="search.standard"
                                :sort-by="sortBy.standard"
                                :sort-desc="sortDesc.standard"
                                :options="tableOptions"
                                :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                                <template v-slot:default="{ items }">
                                    <v-row>
                                        <v-col v-for="item in items" :key="item.id" cols="12">
                                            <v-card elevation="4" :class="getStandardItemClass(item)" :disabled="scalingApp || appScaled">
                                                <v-row no-gutters>
                                                    <v-col cols="2" class="d-flex align-center px-4">{{ item.cpu }}</v-col>
                                                    <v-col cols="3" class="d-flex align-center px-4">{{ toLocaleFixed(item.memory, 0) }} GB</v-col>
                                                    <v-col cols="7" class="text-right pa-4">
                                                        <template
                                                            v-if="item.id === selectedRow.standard && selectedApp.active_resource === 'ncu' && !scalingApp">
                                                            <v-btn color="success" small>
                                                                <v-icon small>check</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <template v-else>
                                                            <v-btn
                                                                color="primary"
                                                                small
                                                                @click="clickRow(item.id, 'standard')"
                                                                :loading="
                                                                    item.id === selectedRow.standard && selectedApp.active_resource === 'ncu' && scalingApp
                                                                ">
                                                                set
                                                            </v-btn>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-iterator>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-alert type="info" text v-if="!canScaleGlobal" class="mb-6">
                                <v-row align="center">
                                    <v-col class="grow">
                                        <div v-if="hpcDisabledReason === 'TURNED_OFF_RESOURCE_POOL'">
                                            Contact your resource pool manager to enable this feature.
                                        </div>
                                        <div v-if="hpcDisabledReason === 'TURNED_OFF_ORG'">Contact your Organization's manager to enable this feature.</div>
                                        <div v-if="hpcDisabledReason === 'ARCHIVED'">
                                            Pay-as-you-go resources are unavailable because the space is archived.
                                        </div>
                                        <div v-if="hpcDisabledReason === 'DISABLED'">Contact Nuvolos support to enable this feature.</div>
                                        <template v-if="!hpcDisabledReason">
                                            <div v-if="!isMasterInstance && currentSpaceType === spaceTypes.EDUCATION_SPACE">
                                                Pay-as-you-go resources are only available in the Master instance in teaching spaces.
                                            </div>
                                            <div v-else>Pay-as-you-go resources are available.</div>
                                        </template>
                                    </v-col>
                                    <v-col class="shrink">
                                        <v-btn v-if="isScalingEnabled" @click="enableScaling()" :loading="settingScaling" small color="primary" outlined>
                                            Enable
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>

                            <template v-if="$isError(nodePools)">
                                <dashboard-error></dashboard-error>
                            </template>
                            <template v-else>
                                <div class="d-flex align-center">
                                    <v-card flat color="grey lighten-3 mr-3">
                                        <v-card-text class="py-2">
                                            <v-radio-group v-model="nodeFamilySelected" row mandatory hide-details dense class="mt-0 pt-0">
                                                <template v-slot:label>
                                                    <div class="mr-3 my-2">Optimized for</div>
                                                </template>
                                                <div class="d-flex flex-wrap mt-n2">
                                                    <v-card
                                                        class="mt-2 mr-2"
                                                        :elevation="nodeFamilySelected === 'cpu' ? 4 : 0"
                                                        :outlined="nodeFamilySelected !== 'cpu'">
                                                        <v-radio value="cpu" class="pa-2 pr-5 mr-0">
                                                            <template v-slot:label>
                                                                <v-icon left small color="purple">mdi-memory</v-icon>
                                                                CPU
                                                            </template>
                                                        </v-radio>
                                                    </v-card>
                                                    <v-card
                                                        class="mt-2 mr-2"
                                                        :elevation="nodeFamilySelected === 'gpu' ? 4 : 0"
                                                        :outlined="nodeFamilySelected !== 'gpu'">
                                                        <v-radio value="gpu" class="pa-2 pr-5 mr-0">
                                                            <template v-slot:label>
                                                                <v-icon left small color="light-green">mdi-memory mdi-rotate-45</v-icon>
                                                                GPU
                                                            </template>
                                                        </v-radio>
                                                    </v-card>
                                                    <v-card
                                                        class="mt-2"
                                                        :elevation="nodeFamilySelected === 'ssd' ? 4 : 0"
                                                        :outlined="nodeFamilySelected !== 'ssd'">
                                                        <v-radio value="ssd" class="pa-2 pr-5 mr-0">
                                                            <template v-slot:label>
                                                                <v-icon left small color="orange">mdi-nas</v-icon>
                                                                Storage
                                                            </template>
                                                        </v-radio>
                                                    </v-card>
                                                </div>
                                            </v-radio-group>
                                        </v-card-text>
                                    </v-card>

                                    <v-spacer></v-spacer>

                                    <v-text-field
                                        prepend-inner-icon="mdi-filter"
                                        autofocus
                                        hide-details
                                        solo
                                        flat
                                        background-color="grey lighten-4"
                                        dense
                                        label="Search for a resource..."
                                        v-model="search.custom"
                                        clearable
                                        class="mr-2"></v-text-field>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="$store.dispatch('appStore/fetchNodePools')" icon v-on="on" :loading="fetchingNodePools">
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Refresh Resource</span>
                                    </v-tooltip>
                                </div>

                                <v-row no-gutters class="py-4">
                                    <v-col cols="1">
                                        <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('cpu', 'custom')">
                                            <span class="caption font-weight-bold">vCPU</span>
                                            <v-icon small :class="headerClasses.custom.cpu">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('memory', 'custom')">
                                            <span class="caption font-weight-bold">Memory</span>
                                            <v-icon small :class="headerClasses.custom.memory">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('gpu_type', 'custom')">
                                            <span class="caption font-weight-bold">GPU Model</span>
                                            <v-icon small :class="headerClasses.custom.gpu_type">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('vram', 'custom')">
                                            <span class="caption font-weight-bold">GPU Memory</span>
                                            <v-icon small :class="headerClasses.custom.vram">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn :ripple="false" plain block class="justify-start text-notransform" @click="headerClicked('ssd', 'custom')">
                                            <span class="caption font-weight-bold">Temp Storage</span>
                                            <v-icon small :class="headerClasses.custom.ssd">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-btn
                                            :ripple="false"
                                            plain
                                            block
                                            class="justify-start text-notransform"
                                            @click="headerClicked('credits_per_hour', 'custom')">
                                            <span class="caption font-weight-bold">Credit/hour</span>
                                            <v-icon small :class="headerClasses.custom.credits_per_hour">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center justify-end">
                                        <span class="caption font-weight-bold px-4 text--secondary">Actions</span>
                                    </v-col>
                                </v-row>
                                <v-data-iterator
                                    :items="customItems"
                                    no-data-text="No resources found."
                                    :search="search.custom"
                                    :sort-by="sortBy.custom"
                                    :sort-desc="sortDesc.custom"
                                    :options="tableOptions"
                                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                                    <template v-slot:default="{ items }">
                                        <v-row>
                                            <v-col v-for="item in items" :key="item.node_pool" cols="12">
                                                <v-card elevation="4" :class="getCustomItemClass(item)" :disabled="scalingApp || appScaled">
                                                    <v-row no-gutters>
                                                        <v-col cols="1" class="d-flex align-center px-4">{{ item.cpu }}</v-col>
                                                        <v-col cols="2" class="d-flex align-center px-4">{{ toLocaleFixed(item.memory, 0) }} GB</v-col>
                                                        <v-col cols="3" class="d-flex align-center px-4">{{ item.gpu_type || '-' }}</v-col>
                                                        <v-col cols="1" class="d-flex align-center px-4">
                                                            <div v-if="item.vram">{{ toLocaleFixed(item.vram, 0) }} GB</div>
                                                            <div v-else>-</div>
                                                        </v-col>
                                                        <v-col cols="2" class="d-flex align-center px-4">
                                                            <div v-if="item.ssd">{{ toLocaleFixed(item.ssd, 0) }} GB</div>
                                                            <div v-else>-</div>
                                                        </v-col>
                                                        <v-col cols="1" class="d-flex align-center px-4">
                                                            {{ item.credits_per_hour.toFixed(precision) }}
                                                        </v-col>
                                                        <v-col cols="2" class="text-right pa-4">
                                                            <template
                                                                v-if="
                                                                    item.node_pool === selectedRow.custom &&
                                                                    selectedApp.active_resource === 'node_pool' &&
                                                                    !scalingApp
                                                                ">
                                                                <v-btn color="success" small>
                                                                    <v-icon small>check</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <template v-else>
                                                                <v-btn
                                                                    color="primary"
                                                                    small
                                                                    :disabled="!canScale(item)"
                                                                    @click="clickRow(item.node_pool, 'custom')"
                                                                    :loading="
                                                                        item.node_pool === selectedRow.custom &&
                                                                        selectedApp.active_resource === 'node_pool' &&
                                                                        scalingApp
                                                                    ">
                                                                    set
                                                                </v-btn>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-iterator>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { mapGetters, mapState } from 'vuex'
import { scaling } from '@/mixins/scaling'
const DashboardError = () => import('@/components/DashboardError.vue')

const activeClass = 'blue lighten-4 elevation-0'
const selectedClass = 'grey lighten-4 elevation-0'

export default {
    name: 'AppScale',
    mixins: [enumsData, scaling],
    components: { DashboardError },
    data() {
        return {
            selectedApp: null,
            tab: 0,
            selectedRow: {
                standard: null,
                custom: null
            },
            search: {
                standard: '',
                custom: ''
            },
            sortBy: {
                standard: 'cpu',
                custom: 'credits_per_hour'
            },
            sortDesc: {
                standard: undefined,
                custom: undefined
            },
            headerClasses: {
                standard: {
                    cpu: 'd-none',
                    memory: 'd-none'
                },
                custom: {
                    cpu: 'd-none',
                    memory: 'd-none',
                    gpu_type: 'd-none',
                    vram: 'd-none',
                    ssd: 'd-none',
                    credits_per_hour: 'd-none'
                }
            },
            nodeFamilySelected: 'cpu',
            fetchingConfig: false,
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25
            },
            resourcePoolBalance: null,
            precision: this.$appConfig?.VUE_APP_DASHBOARD_PRECISION || process.env.VUE_APP_DASHBOARD_PRECISION,
            hpcCanBeEnabled: false,
            hpcDisabledReason: '',
            settingScaling: false,
            mappedResourcePoolId: null,
            scalingApp: false,
            appScaled: false
        }
    },
    created() {
        this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
        this.$store.dispatch('appStore/fetchNodePools')
    },
    computed: {
        NCUHint() {
            return this.isTrialSpace
                ? 'Cannot change Compute unit in trial. Please use credit-based sizes instead.'
                : 'Included resource sizes utilize the compute unit capacity of the plan. 1 Compute unit = 1 vCPU / 4 GB RAM maximum. Larger compute unit setting might result in slower application startup.'
        },
        filteredNCUOptions() {
            return this.NCUOptions.filter(option => {
                return option.cpu <= this.maxNCU
            })
        },
        maxNCU() {
            return this.currentSpaceType !== this.spaceTypes.EDUCATION_SPACE ? 16 : 4
        },
        cpus() {
            return this.nodePools.filter(i => i.cpu && !i.gpu_type && !i.ssd)
        },
        ssd() {
            return this.nodePools.filter(i => i.ssd)
        },
        gpus() {
            return this.nodePools.filter(i => i.gpu_type)
        },
        customItems() {
            switch (this.nodeFamilySelected) {
                case 'cpu':
                    return this.cpus
                case 'gpu':
                    return this.gpus
                case 'ssd':
                    return this.ssd
                default:
                    return []
            }
        },
        fetchingAllData() {
            return this.fetchingNodePools || this.applicationsFetching
        },
        canScaleGlobal() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return this.isSpaceAdmin && this.isMasterInstance && this.currentSpaceData?.beegfs_sync
            } else {
                return this.currentSpaceData?.beegfs_sync
            }
        },
        isScalingEnabled() {
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return this.isSpaceAdmin && this.hpcCanBeEnabled && this.isMasterInstance
            } else {
                return this.isSpaceAdmin && this.hpcCanBeEnabled
            }
        },
        ...mapState('spaceStore', ['mappedResources']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isTrialSpace', 'isSpaceAdmin', 'currentSpaceData']),
        ...mapState('appStore', ['nodePools', 'fetchingNodePools']),
        ...mapState('snapshotStore', ['applications', 'applicationsFetching']),
        ...mapGetters('instanceStore', ['isMasterInstance'])
    },
    methods: {
        headerClicked(column, type) {
            this.resetOtherColumns(column, type)
            if (this.sortBy[type] !== column) {
                this.sortDesc[type] = undefined
            }
            this.sortBy[type] = column
            switch (this.sortDesc[type]) {
                case undefined:
                    this.sortDesc[type] = false
                    this.headerClasses[type][column] = ['d-block', 'rotate-0']
                    break
                case false:
                    this.sortDesc[type] = true
                    this.headerClasses[type][column] = ['d-block', 'rotate-180']
                    break
                case true:
                    this.sortDesc[type] = undefined
                    this.headerClasses[type][column] = 'd-none'
                    break
            }
        },
        resetOtherColumns(currentColumn, type) {
            for (const prop in this.headerClasses[type]) {
                if (prop !== currentColumn) {
                    this.headerClasses[type][prop] = 'd-none'
                }
            }
        },
        getStandardItemClass(item) {
            if (item.id === this.selectedRow.standard) {
                return this.selectedApp.active_resource === 'ncu' ? activeClass : selectedClass
            }
        },
        getCustomItemClass(item) {
            if (item.node_pool === this.selectedRow.custom) {
                return this.selectedApp.active_resource === 'node_pool' ? activeClass : selectedClass
            }
        },
        isCpuType(nodepool) {
            return this.cpus.some(item => item.node_pool === nodepool)
        },
        isSsdType(nodepool) {
            return this.ssd.some(item => item.node_pool === nodepool)
        },
        isGpuType(nodepool) {
            return this.gpus.some(item => item.node_pool === nodepool)
        },
        setSelectedNcu() {
            const ncuOption = this.NCUOptions.find(item => item.cpu === Number(this.selectedApp.ncu))
            this.selectedRow.standard = ncuOption.id
        },
        findNcuById(id) {
            return this.NCUOptions.find(item => item.id === id)
        },
        setSelectedNodepool() {
            const { node_pool: nodepoolId } = this.nodePools.find(item => item.node_pool === this.selectedApp.node_pool)
            if (this.isCpuType(nodepoolId)) {
                this.nodeFamilySelected = 'cpu'
            }
            if (this.isSsdType(nodepoolId)) {
                this.nodeFamilySelected = 'ssd'
            }
            if (this.isGpuType(nodepoolId)) {
                this.nodeFamilySelected = 'gpu'
            }
            this.selectedRow.custom = this.selectedApp.node_pool
        },
        async clickRow(id, type) {
            this.scalingApp = true
            const previousId = this.selectedRow[type]
            this.selectedRow[type] = id
            const previousActiveResource = this.selectedApp.active_resource
            this.selectedApp.active_resource = type === 'standard' ? 'ncu' : 'node_pool'
            const query = type === 'standard' ? { ncu: this.findNcuById(id).cpu } : { node_pool: id }
            try {
                await this.$axios.post(`applications/${this.$route.params.aid}/resource`, query)
                this.scalingApp = false
                this.appScaled = true
                setTimeout(() => {
                    this.$router.push({
                        name: 'snapshot-applications',
                        params: this.$route.params
                    })
                }, 2000)
            } catch (error) {
                this.scalingApp = false
                this.selectedApp.active_resource = previousActiveResource
                this.selectedRow[type] = previousId
                this.$store.dispatch('showSnackBar', { snackBarText: 'There was an error during the request', snackBarIcon: 'error' })
            }
        },
        async fetchHPCStatus() {
            try {
                const { sid } = this.currentSpaceData
                const { data } = await this.$axios.get(`/spaces/${sid}/can_enable_hpc`)
                this.hpcCanBeEnabled = data.can_enable_hpc
                this.hpcDisabledReason = data.reason
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to request HPC integration status, please reach out to support if the issue persists.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        },
        async fetchResourceBalance(rpid) {
            try {
                const { data } = await this.$axios.get(`/resource/${rpid}/balance`)
                this.resourcePoolBalance = data.balance
            } catch (error) {
                this.resourcePoolBalance = error
            }
        },
        canScale(resource) {
            // Not enough credits
            if (resource.credits_per_hour / 6 > this.resourcePoolBalance) return false

            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return this.isSpaceAdmin && this.isMasterInstance && this.currentSpaceData?.beegfs_sync && resource.schedulable
            } else {
                return this.currentSpaceData?.beegfs_sync
            }
        },
        enableScaling() {
            this.$axios
                .patch(`/spaces/${this.$route.params.sid}`, { beegfs_sync: true })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Pay-as-you-go resources turned on',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to enable credit-based sizes, please reach out to support if the issue persists.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.settingScaling = false
                })
        }
    },
    watch: {
        fetchingAllData: {
            handler: function (fetching) {
                if (!fetching) {
                    this.selectedApp = this.applications.find(app => app.aid === Number(this.$route.params.aid))
                }
            },
            immediate: true
        },
        selectedApp: {
            handler: function (resource) {
                if (resource.active_resource === 'ncu') {
                    this.tab = 0
                } else {
                    this.tab = 1
                }
                this.setSelectedNcu()
                this.setSelectedNodepool()
            },
            immediate: true
        },
        mappedResources: {
            handler(newVal) {
                if (newVal) {
                    this.mappedResourcePoolId = Number(Object.keys(newVal.HPC_INTER_COMPUTE)[0])
                    this.fetchResourceBalance(this.mappedResourcePoolId)
                }
            },
            immediate: true
        },
        currentSpaceData() {
            this.fetchHPCStatus()
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .v-breadcrumbs li {
    font-size: inherit;
}

::v-deep .v-card--link:before {
    border-radius: inherit;
}

.rotate-180 {
    transform: rotate(-180deg);
}
.rotate-0 {
    transform: rotate(0);
}
</style>
